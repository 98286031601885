import { Ensure } from './../common'; 

export default class CollectionComponent {
  constructor() {
    this.collectionClass = '.js-collection-slider';
    this.collectionItemHelper = '.helperComplement';
  }

  initSlider() {
    const collectionElement = $(this.collectionClass);

    $(this.collectionItemHelper).remove();

    // if($(this.collectionClass).find('> div > ul > li').length >= 5) {
      $(this.collectionClass).find('> div > ul').slick({
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
        swipe: true,
        prevArrow: '<svg class="collection__arrow collection__arrow--left" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.15748 6.62871C-0.0524934 6.84711 -0.0524934 7.15289 0.15748 7.37129L6.33071 13.8362C6.49869 14.0546 6.83465 14.0546 7.04462 13.8362L7.84252 12.9626C8.05249 12.7441 8.05249 12.4384 7.84252 12.22L2.88714 6.97816L7.84252 1.78003C8.05249 1.56162 8.05249 1.21217 7.84252 1.03744L7.04462 0.163807C6.83465 -0.0546022 6.49869 -0.0546022 6.33071 0.163807L0.15748 6.62871Z" fill="#9692A3"/></svg>',
        nextArrow: '<svg class="collection__arrow collection__arrow--right" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.84252 7.37129C8.05249 7.15289 8.05249 6.84711 7.84252 6.62871L1.66929 0.163807C1.50131 -0.0546018 1.16535 -0.0546018 0.95538 0.163807L0.15748 1.03744C-0.0524935 1.25585 -0.0524935 1.56162 0.15748 1.78003L5.11286 7.02184L0.15748 12.22C-0.0524935 12.4384 -0.0524935 12.7878 0.15748 12.9626L0.95538 13.8362C1.16535 14.0546 1.50131 14.0546 1.66929 13.8362L7.84252 7.37129Z" fill="#9692A3"/></svg>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      });
    // } else {
    //   $(this.collection).find('> div > ul').addClass('collection--disabled');
    // }
  }

  setFlagDiscount() {
    let productElement = document.querySelectorAll('.shelfProduct');
    if (!Ensure.isUndefined(productElement)) {
      [...productElement].forEach((obj) => {
        const oldPriceTag = obj.querySelector('.js-old-price');
        const newPriceTag = obj.querySelector('.js-new-price');
        const flagContainer = obj.querySelector('.shelfProduct__flag');

        if (!Ensure.isUndefined(oldPriceTag)) {
          let formattedOldPrice = oldPriceTag.innerHTML.split('R$ ')[1].replace(/\./g, '').replace(',', '.');
          let formattedNewPrice = newPriceTag.innerHTML.split('R$ ')[1].replace(/\./g, '').replace(',', '.');
          let discountValue = (formattedOldPrice - formattedNewPrice) / formattedOldPrice * 100;

          !isNaN(discountValue) && flagContainer && flagContainer.insertAdjacentHTML('afterbegin', `<span class="shelfProduct__flag--discount">-${discountValue.toFixed(0)}% OFF</span>`);
        }
      });
    }
  }

  load() {
    this.initSlider();
    this.setFlagDiscount();
  }
}