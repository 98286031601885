import axios from 'axios';

const shoppingListEntity = 'SL';
const shoppingListCategoriesEntity = 'SC';
const shoppingListProductsEntity = 'SP';

const apiInstance = axios.create({
	baseURL: '/api/dataentities',
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/vnd.vtex.ds.v10+json'
	}
});

const defaultListsFields = [
	'id',
	'title',
	'isPublic',
	'description',
	'thumbnail',
	'shoppingListCategory'
];

export async function getListByID(id, fields = defaultListsFields) {
	return apiInstance.get(`/${shoppingListEntity}/documents/${id}`, {
		params: {
			_fields: `${fields}`
		}
	});
}

export async function removeListById(id) {
	return apiInstance.delete(`/${shoppingListEntity}/documents/${id}`);
}

export async function getAllPublicLists(fields = defaultListsFields) {
	return apiInstance.get(`/${shoppingListEntity}/search`, {
		params: {
			_where: 'isPublic=true',
			_fields: `${fields}`
		}
	});
}

export async function getAllUserLists(userEmail, fields = defaultListsFields) {
	return apiInstance.get(`/${shoppingListEntity}/search`, {
		params: {
			_where: `user=${userEmail}`,
			_fields: `${fields}`
		}
	});
}

export async function createUserList(listData) {
	const defaultData = {
		isPublic: false,
		thumbnail: null,
		description: null
	};

	return apiInstance.post(`/${shoppingListEntity}/documents`, {
		...defaultData,
		...listData
	});
}

const defaultCategoriesFields = ['id', 'name', 'isPublic'];
/**
 * @param  {} fields
 */
export async function getAllPublicCategories(fields = defaultCategoriesFields) {
	return apiInstance.get(`/${shoppingListCategoriesEntity}/search`, {
		params: {
			_where: 'isPublic=true',
			_fields: `${fields}`
		}
	});
}

export async function getAllUserCategories(
	userEmail,
	fields = defaultCategoriesFields
) {
	return apiInstance.get(`/${shoppingListCategoriesEntity}/search`, {
		params: {
			_where: `user=${userEmail}`,
			_fields: `${fields}`
		}
	});
}

export async function createUserCategory(categoryData) {
	const defaultData = {
		isPublic: false
	};

	return apiInstance.post(`/${shoppingListCategoriesEntity}/documents`, {
		...defaultData,
		...categoryData
	});
}

const defaultProductsFields = ['id', 'skuId', 'ShoppingList'];

export async function getProductsByListID(
	listsID,
	fields = defaultProductsFields
) {
	return apiInstance.get(`/${shoppingListProductsEntity}/search`, {
		params: {
			_where: `ShoppingList=${listsID}`,
			_fields: `${fields}`
		}
	});
}

/**
 * @param  {} fields
 */
export async function getProductsByLists(
	listsIDS,
	fields = defaultProductsFields
) {
	const query = listsIDS.map(listID => `ShoppingList=${listID}`).join(' OR ');

	return apiInstance.get(`/${shoppingListProductsEntity}/search`, {
		params: {
			_where: `(${query})`,
			_fields: `${fields}`
		}
	});
}

export async function addSkuIDToList(skuId, ShoppingListID) {
	return apiInstance.post(`/${shoppingListProductsEntity}/documents`, {
		skuId,
		ShoppingList: ShoppingListID
	});
}

export async function removeProductById(id) {
	return apiInstance.delete(`/${shoppingListProductsEntity}/documents/${id}`);
}
