import axios from 'axios';

const apiInstance = axios.create({
	baseURL: '/api/catalog_system',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
});

export async function getProductsBySearch(filter) {
	return apiInstance.get(`/pub/products/search?${filter}`);
}

export async function getProductsBySKUIDS(SKUIDS) {
	const filter = SKUIDS.map(skuID => `fq=skuId:${skuID}`).join('&');

	return getProductsBySearch(filter);
}

export async function getProductByProductId(productId) {
	return getProductsBySearch(`fq=productId:${productId}`);
}
