import Component from "./b8one.component";
import VtexCategoryService from "Services/b8one.VtexCategoryService";

export default class DepartamentComponent extends Component {
  vtexCategoryService_
  
  constructor(vtexCategoryService) {
    super();

    this.vtexCategoryService_ = vtexCategoryService ?? new VtexCategoryService();

    this.listClass = '.js-departament-list';

    this.listElement = () => this.getElement(this.listClass);
  }

  componentBuilder() {
    this.get();
  }
  
  async get() {
    const res = await this.vtexCategoryService_.getAll(1);

    this.render(res);
    this.slider();
  }
  
  render(data) {
    let itemsHtml = '';
    
    data.forEach(({url, name}) => {
      itemsHtml += `
        <li class="departament__item">
          <a class="departament__link" href="${url}">
            <div class="departament__image">
              <img class="departament__img" src="/arquivos/departamento-thumb-${name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '-').toLowerCase()}.png">
            </div>
            <span class="departament__name">
              ${name}
            </span>
          </a>
        </li>
      `
    });

    this.listElement().innerHTML = '';
    this.listElement().innerHTML = itemsHtml;
  }

  slider() {
    $(this.listClass).slick({
      mobileFirst: true,
      variableWidth: true,
      swipeToSlide: true,
      arrows: false,
      infinite: false,
      dots: true,
    });
  }

  load() {
    this.componentBuilder();
  }
}