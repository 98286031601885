// Components
import CollectionComponent from 'Components/b8one.collectionComponent';
import ProductCardComponent from 'Components/b8one.productCardComponent';
import DepartamentComponent from 'Components/b8one.departamentComponent';

// Services
import { getProductsBySKUIDS } from 'Services/b8one.catalogService';
import {
	getAllPublicLists,
	getProductsByLists
} from 'Services/b8one.shoppingList';

// Utils
import { REAL } from 'Utils/currency';

class Home {
	constructor() {
		this.shoppingLists = [];

		this.productCardComponent_ = new ProductCardComponent();
		this.collectionComponent_ = new CollectionComponent();
		this.departamentComponet_ = new DepartamentComponent();

		this.componentBuilder();
	}

	caroussel() {
		const fullBanner = $('.js-fullbanner');
		const stripeArea = $('.js-stripearea');

		fullBanner.slick({
			infinite: true,
			slidesToShow: 1,
			dots: true,
			prevArrow:
				'<svg class="fullBanner__arrow fullBanner__arrow--left" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.15748 6.62871C-0.0524934 6.84711 -0.0524934 7.15289 0.15748 7.37129L6.33071 13.8362C6.49869 14.0546 6.83465 14.0546 7.04462 13.8362L7.84252 12.9626C8.05249 12.7441 8.05249 12.4384 7.84252 12.22L2.88714 6.97816L7.84252 1.78003C8.05249 1.56162 8.05249 1.21217 7.84252 1.03744L7.04462 0.163807C6.83465 -0.0546022 6.49869 -0.0546022 6.33071 0.163807L0.15748 6.62871Z" fill="white"/></svg>',
			nextArrow:
				'<svg class="fullBanner__arrow fullBanner__arrow--right" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.84252 7.37129C8.05249 7.15289 8.05249 6.84711 7.84252 6.62871L1.66929 0.163807C1.50131 -0.0546017 1.16535 -0.0546017 0.95538 0.163807L0.15748 1.03744C-0.052494 1.25585 -0.052494 1.56162 0.15748 1.78003L5.11286 7.02184L0.15748 12.22C-0.052494 12.4384 -0.052494 12.7878 0.15748 12.9626L0.95538 13.8362C1.16535 14.0546 1.50131 14.0546 1.66929 13.8362L7.84252 7.37129Z" fill="white"/></svg>'
		});

		if (window.outerWidth < 1024) {
			stripeArea.slick({
				infinite: false,
				slidesToShow: 2,
				adaptativeHeight: true,
				// autoplay: true,
				variableWidth: true,
				dots: false,
				arrows: false
			});
		}
	}

	componentBuilder() {
		document.addEventListener('DOMContentLoaded', async event => {
			this.collectionComponent_.load();
			this.productCardComponent_.load();
			this.departamentComponet_.load();

			this.initShoppingListLoadingCarousel();

			await this.updateShoppingLists();

			this.updateShoppingListsDOM();
			this.initShoppingListCarousel();

			this.caroussel();
		});
	}

	createShoppingListItem({ id, title, thumbnail, totalPrice, totalItems }) {
		const imgURL = `/api/dataentities/SL/documents/${id}/thumbnail/attachments/${thumbnail}`;

		return `
      <li class="list__item">
        <a href="/_secure/lista-de-compras/itens?id=${id}" class="list__link">
          <header class="list__header">
            <figure class="list__container-img">
              <img
                alt="${title}"
                src="${imgURL}"
                class="list__img"
              />
            </figure>
          </header>

          <div class="list__content">
            <h6 class="list__title">
              ${title}
            </h6>

            <span class="list__total-items">
              <i class="far fa-box list__total-items-icon"></i>

              <span class="list__total-items-text">${totalItems}</span>
            </span>

            <span class="list__total-price">
              <i class="far fa-dollar-sign list__total-price-icon"></i>

              <span class="list__total-price-text">${totalPrice}</span>
            </span>
          </div>
        </a>
      </li>
    `;
	}

	async updateShoppingLists() {
		const { data: lists } = await getAllPublicLists();

		if (!lists) return;

		const listsIDS = lists.map(list => list.id);

		const { data: allListProducts } = await getProductsByLists(listsIDS);

		if (!allListProducts) return;

		const SKUIDS = [...new Set(allListProducts.map(({ skuId }) => skuId))];

		const { data: products } = await getProductsBySKUIDS(SKUIDS);

		if (!products) return;

		const currentSeller = 0;

		lists.map(list => {
			const listProducts = allListProducts.filter(listProduct => {
				if (listProduct.ShoppingList !== list.id) return;

				return listProduct;
			});

			const totalPrice = listProducts
				.reduce((total, { skuId }) => {
					for (const product of products) {
						const sku = product.items.find(
							({ itemId }) => parseInt(itemId) === parseInt(skuId)
						);

						if (!sku) return;

						const { Price } = sku.sellers[currentSeller].commertialOffer;

						return total.add(Price);
					}

					return total;
				}, REAL('R$ 0,00'))
				.format();

			list.totalPrice = totalPrice;
			list.totalItems = listProducts.length;

			return list;
		});

		this.shoppingLists = lists;
	}

	updateShoppingListsDOM() {
		const shoppingListsContainerElement = document.querySelector(
			'.shopping-list'
		);
		const shoppingListsElement = document.querySelector('.js-shopping-list');

		const shoppingListLoadingElement = document.querySelector(
			'.js-shopping-list-loading-items'
		);

		if (!shoppingListsContainerElement) return;

		if (this.shoppingLists.length === 0) {
			shoppingListsContainerElement.remove();

			return;
		}

		const shoppingListsHTML = this.shoppingLists
			.map(this.createShoppingListItem)
			.join('');

		shoppingListLoadingElement.remove();

		shoppingListsElement.innerHTML = shoppingListsHTML;
	}

	initShoppingListCarousel() {
		if ($('.js-shopping-list')[0]) {
			const prevArrow = `
        <button class="slick-button-arrow slick-button-arrow--left">
          <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.23622 9.46958C-0.0787402 9.78159 -0.0787402 10.2184 0.23622 10.5304L9.49606 19.766C9.74803 20.078 10.252 20.078 10.5669 19.766L11.7638 18.5179C12.0787 18.2059 12.0787 17.7691 11.7638 17.4571L4.33071 9.9688L11.7638 2.5429C12.0787 2.23089 12.0787 1.73167 11.7638 1.48206L10.5669 0.234009C10.252 -0.0780031 9.74803 -0.0780031 9.49606 0.234009L0.23622 9.46958Z" fill="#9692A3"/>
          </svg>
        </button>
      `;

			const nextArrow = `
        <button class="slick-button-arrow slick-button-arrow--right">
          <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.7638 10.5304C12.0787 10.2184 12.0787 9.78159 11.7638 9.46958L2.50394 0.234009C2.25197 -0.0780029 1.74803 -0.0780029 1.43307 0.234009L0.23622 1.48206C-0.0787401 1.79407 -0.0787401 2.23089 0.23622 2.5429L7.66929 10.0312L0.23622 17.4571C-0.0787401 17.7691 -0.0787401 18.2683 0.23622 18.5179L1.43307 19.766C1.74803 20.078 2.25197 20.078 2.50394 19.766L11.7638 10.5304Z" fill="#9692A3"/>
          </svg>
        </button>
      `;

			$('.js-shopping-list').slick({
				prevArrow,
				nextArrow,
				dots: true,
				arrows: false,
				infinite: false,
				slidesToShow: 2,
				mobileFirst: true,
				slidesToScroll: 2,
				variableWidth: true,
				responsive: [
					{
						breakpoint: 1350,
						settings: {
							dots: false,
							arrows: true
						}
					},
					{
						breakpoint: 992,
						settings: {
							dots: false,
							slidesToShow: 3,
							slidesToScroll: 3
						}
					}
				]
			});
		}
	}

	initShoppingListLoadingCarousel() {
		if ($('.js-shopping-list-loading-items')[0]) {
			$('.js-shopping-list-loading-items').slick({
				dots: false,
				arrows: false,
				infinite: false,
				slidesToShow: 4,
				slidesToScroll: 4,
				variableWidth: true
			});
		}
	}
}

new Home();
